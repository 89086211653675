import React, { useState, useCallback, useMemo, createContext } from 'react';
import PropTypes from 'prop-types';

export const ProductContext = createContext({
  productVariation: [],
  addVariation: () => {},
  cleanVariation: () => {}
});

const ProductProvider = ({ children }) => {
  const [productVariation, setProductVariation] = useState([]);

  const addVariation = useCallback(config => {
    setProductVariation(() => config);
  }, []);

  const cleanVariation = useCallback(() => {
    setProductVariation(() => []);
  }, []);

  const contextValue = useMemo(
    () => ({
      productVariation,
      addVariation,
      cleanVariation
    }),
    [productVariation, addVariation, cleanVariation]
  );

  return (
    <ProductContext.Provider value={contextValue}>
      {children}
    </ProductContext.Provider>
  );
};

ProductProvider.propTypes = {
  children: PropTypes.node
};

export default ProductProvider;

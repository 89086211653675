const ROUTES = {
  home: '/',
  about: '/about',
  category: '/category',
  epg: '/epg',
  info: '/info',
  login: '/login',
  viewAll: '/view-all',
  movieDetails: '/movie',
  myContent: '/mycontent',
  paringCode: '/pairing-code',
  profile: '/profile',
  resetPassword: '/reset-password',
  search: '/search',
  show: '/show',
  showDetails: '/tvshow',
  player: '/player'
}; // TODO: Devs please align with your routesMapping

const PROVIDER_TYPE = {
  cms: 'cms',
  i18n: 'i18n',
  ovp: 'ovp'
};

const SHELF_CONTAINER_IDS = {
  Carousel: 'Carousel',
  Default: 'Default',
  Grid: 'Grid',
  HeroBanner: 'HeroBanner',
  FilterSort: 'FilterSort'
};

const { Carousel, Grid, HeroBanner, FilterSort } = SHELF_CONTAINER_IDS;

/* These are manually used templates */
const CUSTOM_CONTAINER_ID_TEMPLATE_MAP = {
  'carousel-link': Carousel,
  'featured-carousel-portrait': Carousel,
  'carousel-landscape': Carousel,
  'grid-portrait-loadmore': Grid,
  'grid-wide-loadmore': Grid
};

/* These are templates from Accedo One Control */
const ACCEDO_CONTROL_CONTAINER_TEMPLATES = {
  hero: 'elevate-hero-banner',
  continueWatching: 'elevate-continue-watching',
  carouselPortrait: 'elevate-carousel-portrait',
  carouselWide: 'elevate-carousel-wide',
  carouselCategoryPortrait: 'elevate-carousel-category-portrait',
  carouselCategoryWide: 'elevate-carousel-category-wide',
  gridHorizontalPortrait: 'elevate-grid-horizontal-portrait',
  gridHorizontalWide: 'elevate-grid-horizontal-wide',
  gridVerticalPortrait: 'elevate-grid-vertical-portrait',
  gridVerticalWide: 'elevate-grid-vertical-wide',
  carouselViewAllPortrait: 'elevate-carousel-view-all-portrait',
  carouselCategories: 'elevate-carousel-categories',
  filterSort: 'elevate-filter-sort', // to be deprecated
  carouselJumpRecommendation: 'elevate-jump-portrait'
};

const DEFAULT_TEMPLATE = ACCEDO_CONTROL_CONTAINER_TEMPLATES.carouselPortrait;

const ACCEDO_CONTROL_CONTAINER_ID_TEMPLATE_MAP = {
  [ACCEDO_CONTROL_CONTAINER_TEMPLATES.hero]: HeroBanner,
  [ACCEDO_CONTROL_CONTAINER_TEMPLATES.continueWatching]: Carousel,
  [ACCEDO_CONTROL_CONTAINER_TEMPLATES.carouselPortrait]: Carousel,
  [ACCEDO_CONTROL_CONTAINER_TEMPLATES.carouselWide]: Carousel,
  [ACCEDO_CONTROL_CONTAINER_TEMPLATES.gridVerticalPortrait]: Grid,
  [ACCEDO_CONTROL_CONTAINER_TEMPLATES.gridVerticalWide]: Grid,
  [ACCEDO_CONTROL_CONTAINER_TEMPLATES.carouselViewAllPortrait]: Carousel,
  [ACCEDO_CONTROL_CONTAINER_TEMPLATES.carouselCategories]: Carousel,
  [ACCEDO_CONTROL_CONTAINER_TEMPLATES.filterSort]: FilterSort,
  [ACCEDO_CONTROL_CONTAINER_TEMPLATES.carouselJumpRecommendation]: Carousel
};

const CONTAINER_ID_TEMPLATE_MAP = {
  ...ACCEDO_CONTROL_CONTAINER_ID_TEMPLATE_MAP,
  ...CUSTOM_CONTAINER_ID_TEMPLATE_MAP
};

const CONTAINER_ITEM_TYPES = {
  Movie: 'movie',
  TvShow: 'tvshow',
  Episode: 'episode',
  Category: 'category',
  Show: 'show',
  Bookmark: 'bookmark',
  Live: 'live'
};

const DETAIL_ROUTES = {
  [CONTAINER_ITEM_TYPES.Movie]: '/movie/:item-id:',
  [CONTAINER_ITEM_TYPES.TvShow]: '/show/:item-id:',
  [CONTAINER_ITEM_TYPES.Bookmark]: '/player',
  [CONTAINER_ITEM_TYPES.Episode]: '/show/:item-id:',
  [CONTAINER_ITEM_TYPES.Category]: '/category/:item-id:',
  [CONTAINER_ITEM_TYPES.ContinueWatching]: '/player'
}; // TODO: Devs please align with your routesMapping

const DETAIL_TYPES = {
  [CONTAINER_ITEM_TYPES.Movie]: 'movie',
  [CONTAINER_ITEM_TYPES.Episode]: 'episode',
  [CONTAINER_ITEM_TYPES.Show]: 'show'
};

const ITEM_TYPES = {
  Wide: 'wide',
  Portrait: 'portrait',
  FeaturedCarouselPortrait: 'featured-carousel-portrait',
  GridPortrait: 'grid-portrait',
  GridLandscape: 'grid-landscape',
  Landscape: 'landscape',
  Square: 'square',
  Bookmark: 'bookmark',
  SeasonEpisode: 'season-episode',
  Categories: 'categories',
  SearchMovie: 'search-movie',
  SearchEpisode: 'search-episode',
  Trend: 'trend',
  Product: 'product'
};

const ANALYTICS_SCREEN_TYPES = {
  epg: 'epg',
  details: 'details',
  login: 'login',
  category: 'category',
  myContent: 'myContent',
  pairing: 'pairing',
  page: 'page',
  player: 'player',
  profile: 'profile',
  resetPassword: 'resetPassword',
  search: 'search',
  settings: 'settings'
};

const PAGE_TEMPLATES = {
  modular: 'elevate-modular-ui',
  myContent: 'elevate-my-content',
  profile: 'elevate-profile',
  search: 'elevate-search',
  signin: 'elevate-sign-in',
  epg: 'elevate-epg',
  info: 'elevate-info',
  player: 'elevate-player',
  category: 'elevate-category',
  about: 'elevate-about',
  pairing: 'elevate-pairing',
  resetPassword: 'elevate-reset-password',
  viewAll: 'elevate-view-all',
  showDetail: 'elevate-show-detail',
  movieDetail: 'elevate-movie-detail'
};

const IMAGE_TYPE = {
  THUMBNAIL: 'thumbnail',
  COVER: 'cover',
  POSTER: 'poster',
  BACKDROP: 'backdrop',
  BANNER: 'banner'
};

// Nba constants
const SEASON_TYPE = {
  PRE: 'PRE',
  POST: 'POST',
  STAR: 'STAR'
};

const GAME_STATUS = {
  SCHEDULED: 'Scheduled',
  LIVE: 'InProgress',
  FINAL: 'Final',
  F_OT: 'F/OT',
  SUSPENDED: 'Suspended',
  POSTPONED: 'Postponed',
  DELAYED: 'Delayed',
  CANCELED: 'Canceled',
  FORFEIT: 'Forfeit',
  NOT_NECESSARY: 'NotNecessary'
};

const TYPE = {
  GAME: 'game',
  TEAM: 'team',
  PLAYER: 'player'
};

const DEFAULT_COLOR = {
  primary: '#535353',
  secondary: '#3A3A3A',
  tertiary: '#A6A6A6',
  quaternary: '#ABABAB'
};

const DUMMY_VIDEO_URL =
  '//d3qnhznroa8hr5.cloudfront.net/videos/bbb_multiaudio.mp4';

export {
  ACCEDO_CONTROL_CONTAINER_TEMPLATES,
  ACCEDO_CONTROL_CONTAINER_ID_TEMPLATE_MAP,
  ANALYTICS_SCREEN_TYPES,
  CONTAINER_ITEM_TYPES,
  CONTAINER_ID_TEMPLATE_MAP,
  DEFAULT_TEMPLATE,
  DETAIL_ROUTES,
  DETAIL_TYPES,
  ITEM_TYPES,
  PROVIDER_TYPE,
  SHELF_CONTAINER_IDS,
  ROUTES,
  PAGE_TEMPLATES,
  IMAGE_TYPE,
  SEASON_TYPE,
  GAME_STATUS,
  TYPE,
  DEFAULT_COLOR,
  DUMMY_VIDEO_URL
};

import React from 'react';
import PropTypes from 'prop-types';

import ContentGrid from '#/components/ContentGrid/ContentGrid';
import HeroItem from '#/components/Item/HeroItem/HeroItem';

import { actionClickItem } from '#/services/analytics';
import useI18n from '#/hooks/useI18n';

import heroBannerTheme from './heroBanner.scss';

const itemWidth = 1920;
const itemHeight = 1080;

const HeroShelf = ({ onClick, items, config = {}, nav = {}, ds }) => {
  const { currentLocale = {} } = useI18n();
  const { dir } = currentLocale;

  return (
    <ContentGrid
      data={items}
      ds={ds}
      dir={dir}
      keyProperty="id"
      itemWidth={itemWidth}
      itemHeight={itemHeight}
      horizontal
      headPadding={0}
      tailPadding={0}
      spacing={0}
      width={1920}
      loop
      theme={heroBannerTheme}
      onClick={source => {
        actionClickItem(source.data, config.displayText);
        onClick(source.data);
      }}
      DisplayComponent={HeroItem}
      displayComponentProps={{ height: itemHeight }}
      nav={{
        ...nav,
        id: config.id || nav.id
      }}
      initialState={{
        id: items?.[0]?.id,
        position: 0
      }}
      showDots
    />
  );
};

HeroShelf.propTypes = {
  onClick: PropTypes.func,
  ds: PropTypes.object.isRequired,
  items: PropTypes.array,
  config: PropTypes.object,
  nav: PropTypes.object
};

export default HeroShelf;

import { ITEM_TYPES } from '#/config/constants';
import AssetItem from './AssetItem/AssetItem';
import SeasonEpisode from './SeasonEpisode/SeasonEpisode';
import CategoryItem from './CategoryItem/CategoryItem';
import ContinueWatchingItem from './ContinueWatchingItem/ContinueWatchingItem';
import ElevateCategoryItem from './ElevateCategoryItem/ElevateCategoryItem';
import ProductItem from './ProductItem/ProductItem';

export const ITEM_TYPE_TO_COMPONENT_MAP = {
  [ITEM_TYPES.Wide]: AssetItem,
  [ITEM_TYPES.Portrait]: AssetItem,
  [ITEM_TYPES.FeaturedCarouselPortrait]: AssetItem,
  [ITEM_TYPES.GridPortrait]: AssetItem,
  [ITEM_TYPES.Landscape]: AssetItem,
  [ITEM_TYPES.Square]: CategoryItem,
  [ITEM_TYPES.Bookmark]: ContinueWatchingItem,
  [ITEM_TYPES.Categories]: ElevateCategoryItem,
  [ITEM_TYPES.SeasonEpisode]: SeasonEpisode,
  [ITEM_TYPES.SearchMovie]: AssetItem,
  [ITEM_TYPES.SearchEpisode]: AssetItem,
  [ITEM_TYPES.Product]: ProductItem
};

/** Dimensions in px */
export const ITEM_SIZE = {
  [ITEM_TYPES.Wide]: { width: 550, height: 380 },
  [ITEM_TYPES.Portrait]: { width: 353, height: 570 },
  [ITEM_TYPES.GridPortrait]: { width: 330, height: 496 },
  [ITEM_TYPES.GridLandscape]: { width: 590, height: 404 },
  [ITEM_TYPES.FeaturedCarouselPortrait]: { width: 330, height: 496 },
  [ITEM_TYPES.Landscape]: { width: 450, height: 260 },
  [ITEM_TYPES.Square]: { width: 200, height: 200 },
  [ITEM_TYPES.Bookmark]: { width: 570, height: 400 },
  [ITEM_TYPES.Categories]: { width: 350, height: 120 },
  [ITEM_TYPES.SeasonEpisode]: { width: 570, height: 400 },
  [ITEM_TYPES.SearchMovie]: { width: 250, height: 375 },
  [ITEM_TYPES.SearchEpisode]: { width: 410, height: 250 },
  [ITEM_TYPES.Product]: { width: 360, height: 316 }
};

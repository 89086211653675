import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useAppConfig from '#/hooks/useAppConfig';
import styles from './roundImage.scss';

const RoundImage = ({
  src,
  style,
  className = '',
  alt = '',
  height,
  width
}) => {
  const { config = {} } = useAppConfig();
  const [imageLoaded, setImageLoaded] = useState(false);

  const styleImgLoading = {
    opacity: 0,
    height
  };
  const wrapperStyle = {
    height,
    width,
    background: `rgba(91, 91, 91, 0.55) url(${config?.appLogo}) no-repeat fixed center`
  };

  const styleImgLoaded = {
    opacity: 1,
    transition: 'opacity 1s',
    height
  };

  const imgPreloadStyles = imageLoaded ? styleImgLoaded : styleImgLoading;

  const finalImgStyle = {
    height: height || '100%',
    width: width || '100%',
    ...style
  };

  return (
    <div style={wrapperStyle} className={classNames(styles.wrapper, className)}>
      <div style={imgPreloadStyles}>
        <img
          alt={alt}
          src={src}
          className={styles.img}
          style={finalImgStyle}
          onLoad={() => {
            setImageLoaded(true);
          }}
        />
      </div>
    </div>
  );
};

RoundImage.propTypes = {
  /** Image src */
  src: PropTypes.string,
  /** Image styles (width, height, ...) */
  style: PropTypes.object,
  /** Image alt text */
  alt: PropTypes.string,
  /** CSS module class name for the image */
  className: PropTypes.string,
  /** Image's dimensions */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default RoundImage;

/**
 * @module services/config
 * @description
 * Service to handle the fetch of config file from the CMS.
 * @return {VikiElevateConfig} The modelised config file.
 */
import cms from '#/providers/cms';
import configModel from '#/models/config';
import { PROVIDER_TYPE } from '#/config/constants';

import fetcher from '../../helpers/fetcher';

const getConfiguration = async () => {
  const rawConfig = await fetcher({
    cacheId: `${PROVIDER_TYPE.cms}-configuration`,
    fetchFn: () => cms.getConfiguration()
  });
  const config = configModel(rawConfig);

  return config;
};

export { getConfiguration };

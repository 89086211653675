import { useContext, useCallback } from 'react';
import { ConfigContext, ConfigSetcontext } from '#/context/ConfigContext';
import {
  getConfiguration,
  getDefaultTheme,
  getRoutesMapping
} from '#/services/cms';

let configRequestPending = false;

const useAppConfig = () => {
  const configContext = useContext(ConfigContext);
  const setConfigContext = useContext(ConfigSetcontext);

  if (setConfigContext === undefined || configContext === undefined) {
    throw new Error('useAppConfig must be used within a ConfigProvider');
  }

  const getConfigData = useCallback(async () => {
    if (configRequestPending) {
      return;
    }
    configRequestPending = true;
    let appConfig = {};
    try {
      appConfig = await getConfiguration();
      const theme = await getDefaultTheme();
      const routesMapping = await getRoutesMapping();
      appConfig.theme = theme;
      appConfig.routesMapping = routesMapping;
      configRequestPending = false;
      setConfigContext({ config: appConfig });
      return appConfig;
    } catch (error) {
      const config = { errorLoading: true };
      setConfigContext({ config });
      return config;
    }
  }, [setConfigContext]);

  const getConfig = useCallback(() => {
    const { config } = configContext;
    if (!config) {
      getConfigData();
      return {};
    }
    return config;
  }, [getConfigData, configContext]);

  return {
    config: getConfig()
  };
};

export default useAppConfig;

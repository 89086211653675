import React from 'react';
import PropTypes from 'prop-types';
import { withFocus } from '@accedo/vdkweb-navigation';
import { SearchIcon, UserIcon, SettingsIcon } from '#/components/Icons/Icons';
import style from './menuItem.scss';

const icons = {
  faUser: ({ className }) => (
    <UserIcon className={`${className} ${style.user}`} />
  ),
  faSearch: ({ className }) => <SearchIcon className={className} />,
  faInfoCircle: ({ className }) => <SettingsIcon className={className} />,
  faProfile: ({ className }) => (
    <UserIcon className={`${className} ${style.user}`} />
  ),
  faSettings: ({ className }) => <SettingsIcon className={className} />
};

/**
 * MenuItem Component
 * @param {Object} props Component Props
 * @param {Function} props.onClick Callback function to dispatch when
 * @param {Object} props.data The content to be displayed
 * @returns {React.ReactComponentElement} Component
 */
const MenuItem = ({ onClick, data, nav }) => {
  const { faIcon, image, itemGroup } = data;
  const userImg = image ? (
    <img className={style.profilePicture} src={image} alt="" />
  ) : null;

  const className =
    itemGroup === 'primary'
      ? `${style.item} ${style.primary}`
      : `${style.item} ${style.secondary}`;
  const itemClassName = `${className} ${
    nav.isFocused ? style.itemFocused : ''
  }`;

  const itemIcon =
    faIcon && icons[faIcon]
      ? icons[faIcon]({ className: `${className} ${style.icon}` })
      : null;

  const itemContent = userImg || itemIcon || data.displayText;

  return (
    <div
      className={itemClassName}
      onMouseOver={nav.onMouseOver}
      onMouseOut={nav.onMouseOut}
    >
      <button onClick={onClick}>{itemContent}</button>
    </div>
  );
};

MenuItem.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.object,
  nav: PropTypes.object
};

export default withFocus(MenuItem);

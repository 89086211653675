import React from 'react';
import PropTypes from 'prop-types';
import Ellipsis from 'react-ellipsis-pjs';
import { withFocus } from '@accedo/vdkweb-navigation';
import ImageWithPreload from '#/components/ImageWithPreload/ImageWithPreload';
import seasonEpisodeTheme from './seasonEpisode.scss';

/**
 * SeasonEpisode Component
 * @param {Object} props Component Props
 * @param {Function} props.onClick Callback function to dispatch when
 * @param {Object} props.theme CSS theme
 * @param {Object} props.data The content to be displayed
 * @returns {React.ReactComponentElement} Component
 */

/** Variable required to get the image's height in vw */
const TITLE_CONTAINER_HEIGHT = 5.2;

const SeasonEpisode = ({
  containerStyle,
  onClick,
  data,
  nav,
  theme = seasonEpisodeTheme
}) => {
  const { progress, title, coverUrl } = data;

  const className = nav.isFocused
    ? `${theme.episode} ${theme.focused}`
    : theme.episode;

  const titleClassName = nav.isFocused
    ? `${theme.title} ${theme.focused}`
    : theme.title;

  const imageHeightNumber =
    containerStyle && +containerStyle.height.replace('vw', '');
  const finalImageHeight = `${imageHeightNumber - TITLE_CONTAINER_HEIGHT}vw`;

  return (
    <div style={{ ...containerStyle }}>
      <div
        className={className}
        onClick={onClick}
        onMouseEnter={nav.onMouseOver}
        onMouseLeave={nav.onMouseOut}
        role="button"
      >
        <ImageWithPreload
          className={theme.coverImage}
          src={coverUrl}
          alt={title}
          height={finalImageHeight}
          width={containerStyle.width}
        />
        {progress > 0 && (
          <div className={theme.progress}>
            <span style={{ width: `${progress}%` }} />
          </div>
        )}
        <div className={titleClassName}>
          <Ellipsis lines={1} text={title} />
        </div>
      </div>
    </div>
  );
};

SeasonEpisode.propTypes = {
  containerStyle: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  nav: PropTypes.object.isRequired,
  theme: PropTypes.object
};

export default withFocus(SeasonEpisode);

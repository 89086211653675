/**
 * @module services/movie
 * @description
 * Service to handle the fetch of movies
 */
import ovp from '#/providers/ovp';
import Movie from '#/models/movie/movie';
import { PROVIDER_TYPE } from '#/config/constants';

import fetcher from '../../helpers/fetcher';

const getMovie = async id => {
  const rawMovie = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-movie-${id}`,
    fetchFn: () => ovp.getMovieById(id)
  });
  return Movie(rawMovie);
};

export { getMovie };

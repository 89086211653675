import androidTv from '@accedo/xdk-device-android-webview';
import playstationMsdk from '@accedo/xdk-device-playstation-msdk';
import tizen from '@accedo/xdk-device-samsung-tizen';
import microsoftUwp from '@accedo/xdk-device-microsoft-uwp';
import webos from '@accedo/xdk-device-lg-webos';
import workstation, { ID as WORKSTATION } from '@accedo/xdk-device-workstation';

const pollingNetwork = {
  polling: {
    interval: 60,
    url: 'https://jsonplaceholder.typicode.com/posts/1'
  }
};

const CONFIG = {
  devices: {
    packages: [
      androidTv,
      webos,
      tizen,
      playstationMsdk,
      microsoftUwp,
      workstation
    ],
    details: {
      [WORKSTATION]: {
        network: pollingNetwork
      }
    },
    // Storages configurations
    storages: {
      cookie: {
        name: 'elevate-app'
      }
    }
  },
  logging: {
    level: -1
  }
};

export default CONFIG;

/**
 * @module services/query
 * @description
 * Service to fetch data to the OVP using queries
 */
import ovp from '#/providers/ovp';
import Item from '#/models/item/item';
import { PROVIDER_TYPE } from '#/config/constants';

import fetcher from '../../helpers/fetcher';

/**
 * @param {String} query The query for the OVP.
 * @param {String} contextData Context for being interpolated with the query.
 * @param {Number} from Init index for retriving data.
 * @param {Number} to Final index for retriving data.
 * @param {Number} itemsPerPage Max number of item per query.
 * @param {String} sortBy Key for ordering data.
 * @returns {Array} Array of Items
 */

const getItemsByQuery = async ({
  query,
  pageNumber,
  contextData,
  itemsPerPage,
  sortBy = ''
}) => {
  // Match with '{{something}}'
  const parameters = query?.match(/[^{}]+(?=})/g);
  let interpolatedQuery = query;

  parameters?.forEach(parameter => {
    if (parameter && contextData && contextData[parameter]) {
      const value = contextData[parameter];
      interpolatedQuery = interpolatedQuery.replace(
        new RegExp(`{{${parameter}}}`),
        () => value.toLowerCase()
      );
    }
  });

  const result = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-query-${interpolatedQuery}-${pageNumber}`,
    fetchFn: () =>
      ovp.getItemsByQuery({
        query: interpolatedQuery,
        pageNumber,
        sortBy,
        itemsPerPage
      })
  });

  if (!result) {
    console.warn(`[debug] Not response from query: ${query}`);
  }

  return {
    items: result?.items?.map(item => Item(item)),
    total: result?.total
  };
};

export { getItemsByQuery };

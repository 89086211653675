import React from 'react';
import PropTypes from 'prop-types';
import Ellipsis from 'react-ellipsis-pjs';
import classNames from 'classnames';
import { withFocus } from '@accedo/vdkweb-navigation';
import { FocusDiv } from '@accedo/vdkweb-tv-ui';
import RoundImage from '#/components/RoundImage/RoundImage';
import styles from './productItem.scss';

const ProductItem = ({ containerStyle, data: item, height, nav, width }) => {
  const wrapperStyle = {
    ...containerStyle,
    ...(width ? { width } : {}),
    ...(height ? { height } : {})
  };

  return (
    <FocusDiv
      nav={nav}
      style={wrapperStyle}
      className={classNames(styles.wrapper, {
        [styles.focused]: nav?.isFocused
      })}
    >
      <RoundImage
        src={item?.images?.imageList?.[0]?.highRes?.url}
        className={styles.imgContainer}
        height={180}
        width={180}
        alt={item?.images?.altText}
      />
      <div className={styles.txtContainer}>
        {item?.price && (
          <span className={styles.tag}>{`$ ${item?.price?.value} `}</span>
        )}
        <Ellipsis lines={2} text={item?.title} />
      </div>
    </FocusDiv>
  );
};

ProductItem.propTypes = {
  containerStyle: PropTypes.object,
  data: PropTypes.object.isRequired,
  height: PropTypes.any,
  nav: PropTypes.object,
  width: PropTypes.any
};

export default withFocus(ProductItem);
